.player-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;

  video::-webkit-media-controls-fullscreen-button {
    display: none;
  }
}

#container {
  display: grid;
  grid-template-columns: 1fr;
  aspect-ratio: 16/9;
  align-items: center;

  .video-player,
  .drawing {
    grid-column: 1/-1;
    grid-row: 1/-1;
  }
  .drawing {
    z-index: 1;
  }
}

.btn-fs {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 5px;
  color: white;
  border: 1px solid white;
  border-radius: 5px;
  z-index: 10;
  cursor: pointer;
}
svg {
  stroke: transparent;
  stroke-width: 0px;
}
