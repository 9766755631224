.lm-icon {
  --icon-size: var(--bv-size-icon-base);
  height: auto;  
  svg {
    width: var(--icon-size);
    height: auto;
  }
  &--original {
    --icon-size: var(--bv-size-icon-original);
  }
  &--2xs {
    --icon-size: 0.625rem;
  }
  &--xs {
    --icon-size: var(--bv-size-icon-xs);
  }
  &--sm {
    --icon-size: var(--bv-size-icon-sm);
  }
  &--lg {
    --icon-size: var(--bv-size-icon-lg);
  }
  &--xl {
    --icon-size: var(--bv-size-icon-xl);
  }
  &--2xl {
    --icon-size: var(--bv-size-icon-2xl);
  }
  &--3xl {
    --icon-size: var(--bv-size-icon-3xl);
  }
  &--4xl {
    --icon-size: var(--bv-size-icon-4xl);
  }
  &--5xl {
    --icon-size: var(--bv-size-icon-5xl);
  }
  &--6xl {
    --icon-size: var(--bv-size-icon-6xl);
  }
}

.lm-icon-color {
  color: var(--org-color);
}
