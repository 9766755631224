.lm-dropdown {
  background: #ffffff;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2), 0px 2px 10px rgba(0, 0, 0, 0.1);
  padding: 4px 10px;
  position: absolute;  
  z-index: 2;
  margin-top: 6px;
  right: 0;
  width: max-content;  
}
