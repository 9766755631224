.tags {
  // Default shadow
  --input-box-shadow: inset 0 0 0 var(--bv-size-border-width-base) var(--bv-color-border-form-fields-base);
  // Hover shadow
  --input-hover-box-shadow: inset 0 0 0 var(--bv-size-border-width-base) var(--bv-color-border-form-fields-hover);
  // Focus shadow
  --input-focus-box-shadow: inset 0 0 0 var(--bv-size-border-width-base) var(--bv-color-border-form-fields-focus);

  border-radius: var(--bv-size-border-radius-base);
  box-shadow: var(--input-box-shadow);
  transition: 0.15s linear all;
  padding: 0.125rem;

  &:focus-within {
    --input-box-shadow: inset 0 0 0 var(--bv-size-border-width-lg) var(--bv-color-border-form-fields-focus);
  }
  &:hover {
    box-shadow: var(--input-hover-box-shadow);
  }
}
