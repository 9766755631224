.lm-checkbox {
  --check-border-width: var(--bv-size-border-radius-check);
  --check-border-color: var(--bv-color-border-check-unselected-base);
  --check-background-color: var(--bv-color-background-check-unselected-base);
  --check-label-position: 0;
  cursor: pointer;

  &__element {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;

    color: var(--bv-color-text-tag-base);
    width: 1.25rem;
    height: 1.25rem;
    border: var(--check-border-width) solid var(--check-border-color);
    border-radius: var(--bv-size-border-radius-check);

    display: grid;
    place-content: center;
    background-color: var(--check-background-color);
    order: var(--checkbox-position);

    &::before {
      content: "";
      width: 0.65rem;
      height: 0.65rem;
      transform: scale(0);
      transition: 120ms transform ease-in-out;
      box-shadow: inset 1em 1em #000;
      --check-border-width: var(--bv-size-border-width-check-selected-base);
      --check-border-color: var(--bv-color-border-check-selected-base);
      border: var(--bv-size-border-width-check-selected-base) solid var(--bv-color-border-check-selected-base);

      background-color: var(--check-background-color);
      transform-origin: bottom left;
      clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    }

    &:checked::before {
      transform: scale(1);
    }

    &:hover {
      --check-border-color: var(--bv-color-border-check-unselected-hover);
    }

    &:focus {
      --check-border-color: var(--bv-color-border-check-unselected-pressed);
    }

    &:checked {
      /* --check-border-color: var(--bv-color-selection-focus-base); */
      --check-background-color: var(--bv-color-background-check-selected-base);

      --check-border-color: var(--bv-color-border-check-selected-hover);
    }

    &:checked:hover {
      --check-border-color: var(--bv-color-border-check-selected-hover);
      --check-background-color: var(--bv-color-background-check-selected-hover);
    }

    &:checked:focus {
      --check-border-color: var(--bv-color-border-check-selected-pressed);
    }
  }

  &__label {
    order: var(--check-label-position);
  }

  &--disabled {
    .lm-checkbox__element {
      background: var(--bv-color-background-check-disabled-base);
      border: var(--bv-size-border-width-check-disabled-base) solid var(--bv-color-border-check-disabled-base);
      &::before {
        box-shadow: inset 1em 1em #1b3069;
      }
    }
  }

  &--indeterminate {
    .lm-checkbox__element {
      --check-background-color: var(--bv-color-background-check-selected-base);

      &::before {
        box-shadow: inset 1em 1em #fff;
        clip-path: polygon(0 40%, 100% 40%, 100% 60%, 0% 60%);
        transform: scale(1);
      }
    }
  }

  &--error {
    .lm-checkbox__element {
      --check-border-color: var(--bv-color-destructive-500);
    }
  }

  &--label-left {
    --check-label-position: -1;
  }
}
