input[type="date"] {
  display: block;
  appearance: textfield;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  min-height: 1.2rem;
}

.lm-input {
  // Default shadow
  --input-box-shadow: inset 0 0 0 var(--bv-size-border-width-base) var(--bv-color-border-form-fields-base);
  // Hover shadow
  --input-hover-box-shadow: inset 0 0 0 var(--bv-size-border-width-base) var(--bv-color-border-form-fields-hover);
  // Focus shadow
  --input-focus-box-shadow: inset 0 0 0 var(--bv-size-border-width-base) var(--bv-color-border-form-fields-focus);

  &__help {
    color: var(--bv-color-text-soft);
    font-family: var(--bv-typography-text-body-sm-font-family);
    font-weight: var(--bv-typography-text-body-sm-font-weight);
    line-height: var(--bv-typography-text-body-sm-line-height);
    text-transform: var(--bv-typography-text-body-sm-text-case);
    font-size: var(--bv-typography-text-body-sm-font-size);
    margin: 0.5rem 0;
  }

  &__selected {
    display: flex;
    align-items: center;
    gap: 2px;
    font-size: 12px;
    flex-shrink: 0;
  }

  &__required {
    color: var(--bv-color-icon-required);
  }

  &__icon {
    color: var(--bv-color-text-form-fields-label);
    &--has-action {
      cursor: pointer;
    }
  }

  &__wrapper {
    color: var(--bv-color-text-form-fields-default);
    display: grid;
    grid-template-columns: 1fr auto;
    gap: var(--bv-size-space-3xs);
    border-radius: var(--bv-size-border-radius-base);
    box-shadow: var(--input-box-shadow);
    font-family: var(--bv-typography-text-body-base-font-family);
    padding: var(--bv-size-space-xs) var(--bv-size-space-sm);
    transition: 0.15s linear all;
    background-color: var(--bv-color-background-form-fields-base);

    &:focus-within {
      --input-box-shadow: inset 0 0 0 var(--bv-size-border-width-lg) var(--bv-color-border-form-fields-focus);
    }
    &:hover {
      box-shadow: var(--input-hover-box-shadow);
    }

    &--tags {
      box-shadow: none;

      &:hover,
      &:focus-within {
        box-shadow: none;
      }
    }
  }

  &__success {
    color: var(--bv-color-success-500);
  }

  &__inner {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: var(--bv-size-space-2xs);
  }

  &__prefix-icon {
    pointer-events: none;
  }

  &__element {
    outline: none;
    border: none;
    max-width: 100%;
    width: 100%;
    flex: 1;
    background-color: inherit;
    font-size: var(--bv-typography-text-body-base-font-size);
    line-height: var(--bv-typography-text-button-base-line-height);
    &:focus {
      caret-color: var(--bv-color-focus-cursor);
    }
    ::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: var(--bv-color-text-form-fields-hint);
    }
    ::-moz-placeholder {
      /* Firefox 19+ */
      color: var(--bv-color-text-form-fields-hint);
    }
    :-ms-input-placeholder {
      /* IE 10+ */
      color: var(--bv-color-text-form-fields-hint);
    }
    :-moz-placeholder {
      /* Firefox 18- */
      color: var(--bv-color-text-form-fields-hint);
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    gap: var(--bv-size-space-2xs);
  }

  &--success {
    --input-box-shadow: inset 0 0 0 var(--bv-size-border-width-lg) var(--bv-color-success-500);
    --input-hover-box-shadow: var(--input-box-shadow);
    --input-focus-box-shadow: var(--input-box-shadow);
    &__icon {
      color: var(--bv-color-success-500);
      pointer-events: none;
    }
  }

  &--error {
    --input-box-shadow: inset 0 0 0 var(--bv-size-border-width-lg) var(--bv-color-destructive-500);
    --input-hover-box-shadow: var(--input-box-shadow);
    --input-focus-box-shadow: var(--input-box-shadow);
  }

  &--readonly {
    .lm-input__element {
      cursor: pointer;
    }
  }

  &--disabled {
    box-shadow: inset 0 0 0 var(--bv-size-border-width-base) var(--bv-color-border-fields-base);

    caret-color: transparent;
    pointer-events: none;

    .lm-input__icon,
    .lm-input__actions {
      color: var(--bv-color-border-fields-base);
    }

    .lm-input__wrapper {
      background: var(--bv-color-background-form-fields-disabled);
    }

    &:hover {
      box-shadow: inset 0 0 0 var(--bv-size-border-width-base) var(--bv-color-border-form-fields-hover);
    }
  }
}
