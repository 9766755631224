.lm-label {
  color: var(--bv-color-text-soft);
  font-family: var(--bv-typography-text-label-font-family);
  font-size: var(--bv-typography-text-label-font-size);
  font-weight: var(--bv-typography-text-label-font-weight);
  line-height: var(--bv-typography-text-label-line-height);
  margin-bottom: var(--bv-size-space-3xs);
  &__required {
    color: var(--bv-color-icon-required);
  }
}
