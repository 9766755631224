@font-face {
  font-family: "Spartan";
  src: local("Spartan ExtraBold"), url("../../assets/fonts/Spartan/Spartan-ExtraBold.woff2") format("woff2"),
    url("../../assets/fonts/Spartan/Spartan-ExtraBold.woff") format("woff"), url("../../assets/fonts/Spartan/Spartan-ExtraBold.ttf") format("ttf");
  font-style: normal;
  font-weight: 800;
  font-display: swap;
}

body {
  font-family: var(--bv-font-family-brand), sans-serif;
  color: var(--bv-color-secondary-500);
}
