.lm-menu-item {
  &:hover,
  &--active {
    opacity: 1;
  }
  &--active:before {
    content: "";
    height: 100%;
    width: 0.5rem;
    border-radius: 0.25rem;
    background-color: var(--bv-color-button-primary-base);
    position: absolute;
  }
}
