.lm-autocomplete-selections {
  display: inline-flex;
  gap: 0.5rem;
  flex-wrap: wrap;
}
.lm-autocomplete-panel-inner {
  max-height: 20rem;
  overflow-y: auto;
}

.lm-drowdown-panel {
  background-color: #fff;
  width: 100%;
  box-shadow: var(--bv-shadow-box-popover);
  margin-top: 0.5rem;
  padding: 0.25rem 0;
  overflow-y: auto;
}

.lm-option {
  color: var(--bv-color-text-base);
  font-size: var(--bv-typography-text-body-base-font-size);
  padding: var(--bv-size-space-sm) var(--bv-size-space-base);
  text-transform: var(--bv-typography-text-body-base-text-case);
  font-family: var(--bv-typography-text-body-base-font-family);
  line-height: var(--bv-typography-text-body-base-line-height);
  cursor: pointer;
  transition: 0.3s background-color linear;
  display: flex;
  gap: 0.5rem;
  align-items: center;

  &:hover {
    background-color: var(--bv-color-background-table-row-selected-base);
  }
  &--active {
    background-color: var(--bv-color-background-table-row-selected-base);
    &:hover {
      background-color: var(--bv-color-background-table-row-selected-hover);
    }
  }
  &--disabled {
    pointer-events: none;
    opacity: 0.4;
  }
}
