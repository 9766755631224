.lm-button {
  --button-padding-x: 1rem;
  --button-padding-y: 0.625rem;
  --button-icon-position: 0;
  --button-font-family: var(--bv-typography-text-button-base-font-family);
  --button-font-weight: var(--bv-typography-text-button-base-font-weight);
  --button-line-height: var(--bv-typography-text-button-base-line-height);
  --button-font-size: var(--bv-typography-text-button-base-font-size);
  --button-border-width: 0;
  --button-border-style: solid;

  font-family: var(--button-font-family);
  font-weight: var(--button-font-weight);
  line-height: var(--button-line-height);
  font-size: var(--button-font-size);

  background-color: var(--button-background);
  color: var(--button-text-color);
  gap: var(--bv-size-space-button-base-gap);
  border-color: var(--button-border-color);
  border-style: var(--button-border-style);
  border-width: var(--button-border-width);
  transition: 0.1s linear all;
  padding: var(--button-padding-y) var(--button-padding-x);

  &__icon {
    order: var(--button-icon-position);
  }

  &:hover {
    background-color: var(--button-hover-background);
    border-color: var(--button-hover-border-color);
    color: var(--button-hover-text-color);
  }
  &:active {
    background-color: var(--button-active-background);
    border-color: var(--button-active-border-color);
    color: var(--button-active-text-color);
  }

  // TYPES
  &--primary {
    --solid-background: var(--bv-color-button-primary-base);
    --solid-hover-background: var(--bv-color-button-primary-hover);
    --solid-active-background: var(--bv-color-button-primary-pressed);
    --solid-text-color: var(--bv-color-button-primary-on-contrast);
    --solid-border-color: var(--bv-color-button-primary-base);
    --solid-hover-border-color: var(--bv-color-button-primary-on-contrast);
    --solid-active-border-color: var(--bv-color-button-primary-on-contrast);

    --outline-background: transparent;
    --outline-hover-background: var(--bv-color-button-primary-on-contrast);
    --outline-active-background: var(--bv-color-button-primary-on-contrast);
    --outline-color: var(--bv-color-button-primary-base);
    --outline-hover-color: var(--bv-color-button-primary-hover);
    --outline-active-color: var(--bv-color-button-primary-pressed);
  }
  &--secondary {
    --solid-background: var(--bv-color-button-secondary-light-base);
    --solid-hover-background: var(--bv-color-button-secondary-light-hover);
    --solid-active-background: var(--bv-color-button-secondary-light-pressed);
    --solid-text-color: var(--bv-color-button-secondary-light-on-contrast);
    --solid-border-color: var(--bv-color-button-secondary-light-base);
    --solid-hover-border-color: var(--bv-color-button-secondary-on-contrast);
    --solid-active-border-color: var(--bv-color-button-secondary-on-contrast);

    --outline-background: transparent;
    --outline-hover-background: var(--bv-color-button-secondary-on-contrast);
    --outline-active-background: var(--bv-color-button-secondary-on-contrast);
    --outline-color: var(--bv-color-button-secondary-base);
    --outline-hover-color: var(--bv-color-button-secondary-hover);
    --outline-active-color: var(--bv-color-button-secondary-pressed);
  }
  &--success {
    --solid-background: var(--bv-color-button-success-base);
    --solid-hover-background: var(--bv-color-button-success-hover);
    --solid-active-background: var(--bv-color-button-success-pressed);
    --solid-text-color: var(--bv-color-button-success-on-contrast);
    --solid-border-color: var(--bv-color-button-success-base);
    --solid-hover-border-color: var(--bv-color-button-success-on-contrast);
    --solid-active-border-color: var(--bv-color-button-success-on-contrast);

    --outline-background: transparent;
    --outline-hover-background: var(--bv-color-button-success-on-contrast);
    --outline-active-background: var(--bv-color-button-success-on-contrast);
    --outline-color: var(--bv-color-button-success-base);
    --outline-hover-color: var(--bv-color-button-success-hover);
    --outline-active-color: var(--bv-color-button-success-pressed);
  }
  &--destructive {
    --solid-background: var(--bv-color-button-destructive-base);
    --solid-hover-background: var(--bv-color-button-destructive-hover);
    --solid-active-background: var(--bv-color-button-destructive-pressed);
    --solid-text-color: var(--bv-color-button-destructive-on-contrast);
    --solid-border-color: var(--bv-color-button-destructive-base);
    --solid-hover-border-color: var(--bv-color-button-destructive-on-contrast);
    --solid-active-border-color: var(--bv-color-button-destructive-on-contrast);

    --outline-background: transparent;
    --outline-hover-background: var(--bv-color-button-destructive-on-contrast);
    --outline-active-background: var(--bv-color-button-destructive-on-contrast);
    --outline-color: var(--bv-color-button-destructive-base);
    --outline-hover-color: var(--bv-color-button-destructive-hover);
    --outline-active-color: var(--bv-color-button-destructive-pressed);
  }
  &--organization {
    --solid-background: var(--org-color);
    --solid-hover-background: var(--org-color);
    --solid-active-background: var(--org-color);
    --solid-text-color: #fff;
    --solid-border-color: var(--org-color);
    --solid-hover-border-color: var(--org-color);
    --solid-active-border-color: var(--org-color);

    --outline-background: transparent;
    --outline-hover-background: var(--org-color);
    --outline-active-background: var(--org-color);
    --outline-color: var(--org-color);
    --outline-hover-color: var(--org-color);
    --outline-active-color: var(--org-color);

    &:hover {
      opacity: 0.8;
    }
  }
  // STYLES
  &--solid {
    --button-background: var(--solid-background);
    --button-border-color: var(--solid-border-color);
    --button-text-color: var(--solid-text-color);

    --button-hover-background: var(--solid-hover-background);
    --button-hover-border-color: var(--solid-border-color);
    --button-hover-text-color: var(--solid-text-color);

    --button-active-background: var(--solid-active-background);
    --button-active-border-color: var(--solid-border-color);
    --button-active-text-color: var(--solid-text-color);
  }
  &--outline {
    --button-background: var(--outline-background);
    --button-border-color: var(--outline-color);
    --button-text-color: var(--outline-color);

    --button-hover-background: var(--outline-hover-background);
    --button-hover-border-color: var(--outline-hover-color);
    --button-hover-text-color: var(--outline-hover-color);

    --button-active-background: var(--outline-active-background);
    --button-active-border-color: var(--outline-active-color);
    --button-active-text-color: var(--outline-active-color);
  }
  &--clear {
    --button-background: transparent;
    --button-border-color: transparent;
    --button-text-color: var(--outline-color);

    --button-hover-background: transparent;
    --button-hover-text-color: var(--outline-hover-color);
    --button-active-background: transparent;
    --button-active-text-color: var(--outline-active-color);
  }

  // SIZES
  &--extra-small {
    --button-padding-x: 0.5rem;
    --button-padding-y: 0.5rem;
    min-width: 0;
  }
  &--small {
    --button-padding-x: 1rem;
    --button-padding-y: 0.5rem;
  }
  &--medium {
    --button-padding-x: 1.5rem;
    --button-padding-y: 0.75rem;
  }
  &--large {
    --button-padding-x: 1rem;
    --button-padding-y: 1rem;
    --button-font-family: var(--bv-typography-text-button-lg-font-family);
    --button-font-weight: var(--bv-typography-text-button-lg-font-weight);
    --button-line-height: var(--bv-typography-text-button-lg-line-height);
    --button-font-size: var(--bv-typography-text-button-lg-font-size);
  }

  &--radius {
    border-radius: var(--bv-size-border-radius-button);
  }

  &--icon-right {
    --button-icon-position: 1;
  }
  &--disabled {
    pointer-events: none;
    opacity: 0.4;
  }
}
