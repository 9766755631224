.bg-orgColor {
  background-color: var(--org-color);
}

.text-orgColor {
  color: var(--org-color);  
}

.border-orgColor {
  border-color: var(--org-color);
}

.theme {
  &-dark-blue {
    --org-color: #385ab5;
  }
  &-light-blue {
    --org-color: #59adf0;
  }
  &-dark-green {
    --org-color: #147121;
  }
  &-light-green {
    --org-color: #61bb4a;
  }
  &-red {
    --org-color: #d32f2f;
  }
  &-orange {
    --org-color: #df7133;
  }
  &-pink {
    --org-color: #cd519d;
  }
  &-purple {
    --org-color: #7c36d7;
  }
}
