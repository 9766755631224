.answer {
  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 1.75rem;
    left: -0.375rem;
    height: 0.75rem;
    width: 0.75rem;
    background-color: var(--bv-color-neutral-200);
    border-radius: 100%;
  }
}
