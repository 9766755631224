.load-image {
  --dimesions: 0;
  width: var(--dimesions);
  height: var(--dimesions);
  position: relative;

  &__container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: inherit;
    height: inherit;
    cursor: pointer;
  }

  &__file {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 0.75rem;
  }

  &__overlay {
    position: absolute;
    background: rgba(0, 0, 0, 0.5);
    color: #fff;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.75rem;
  }

  &--sm {
    --dimesions: 2.5rem;
  }
  &--md {
    --dimesions: 4rem;
  }
  &--lg {
    --dimesions: 6.25rem;
  }
}
