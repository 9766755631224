/**
 * Do not edit directly
 * Generated on Tue, 21 Feb 2023 08:48:59 GMT
 */

:root {
  --bv-size-avatar-base: 1.5rem;
  --bv-size-avatar-lg: 2rem;
  --bv-size-avatar-xl: 2.5rem;
  --bv-size-avatar-2xl: 3rem;
  --bv-size-avatar-3xl: 3.5rem;
  --bv-size-avatar-4xl: 4rem;
  --bv-size-border-radius-avatar-lg: 1.5rem;
  --bv-size-border-radius-avatar-base: 1rem;
  --bv-size-border-radius-avatar-sm: 0.75rem;
  --bv-size-border-radius-badge: 3.125rem;
  --bv-size-border-radius-banner: 0;
  --bv-size-border-radius-button: 0.25rem;
  --bv-size-border-radius-calendar-day-container: 0.1875rem;
  --bv-size-border-radius-check: 0.25rem;
  --bv-size-border-radius-radio: 3.125rem;
  --bv-size-border-radius-none: 0;
  --bv-size-border-radius-xs: 0.125rem;
  --bv-size-border-radius-sm: 0.1875rem;
  --bv-size-border-radius-base: 0.25rem;
  --bv-size-border-radius-lg: 0.375rem;
  --bv-size-border-radius-xl: 0.5rem;
  --bv-size-border-radius-2xl: 0.75rem;
  --bv-size-border-radius-3xl: 1rem;
  --bv-size-border-radius-4xl: 1.5rem;
  --bv-size-border-radius-5xl: 2rem;
  --bv-size-border-radius-6xl: 2.5rem;
  --bv-size-border-radius-7xl: 3rem;
  --bv-size-border-radius-circle: 3.125rem;
  --bv-size-border-radius-dropfile: 0.25rem;
  --bv-size-border-radius-form-fields: 0.25rem;
  --bv-size-border-radius-popover: 0.25rem;
  --bv-size-border-radius-spinner: 3.125rem;
  --bv-size-border-radius-tag: 3.125rem;
  --bv-size-border-radius-toast: 0.25rem;
  --bv-size-border-radius-toggle: 1rem;
  --bv-size-border-radius-tooltip: 0.25rem;
  --bv-size-space-bagde-horizontal: 0.5rem;
  --bv-size-space-bagde-vertical: 0;
  --bv-size-space-banner-horizontal: 1rem;
  --bv-size-space-banner-vertical: 1rem;
  --bv-size-space-banner-gap: 1rem;
  --bv-size-space-banner-actions-padding: 0.5rem;
  --bv-size-space-button-base-gap: 0.5rem;
  --bv-size-space-button-base-horizontal: 1rem;
  --bv-size-space-button-base-vertical: 0.5rem;
  --bv-size-space-button-sm-gap: 0.5rem;
  --bv-size-space-button-sm-horizontal: 1rem;
  --bv-size-space-button-sm-vertical: 0.25rem;
  --bv-size-space-button-lg-gap: 0.5rem;
  --bv-size-space-button-lg-horizontal: 1rem;
  --bv-size-space-button-lg-vertical: 1rem;
  --bv-size-space-button-link-horizontal: 0;
  --bv-size-space-3xs: 0.25rem;
  --bv-size-space-2xs: 0.5rem;
  --bv-size-space-xs: 0.625rem;
  --bv-size-space-sm: 0.75rem;
  --bv-size-space-base: 1rem;
  --bv-size-space-lg: 1.25rem;
  --bv-size-space-xl: 1.5rem;
  --bv-size-space-2xl: 2rem;
  --bv-size-space-3xl: 2.5rem;
  --bv-size-space-4xl: 3rem;
  --bv-size-space-5xl: 3.5rem;
  --bv-size-space-6xl: 4rem;
  --bv-size-space-7xl: 4.5rem;
  --bv-size-space-8xl: 5rem;
  --bv-size-space-none: 0;
  --bv-size-space-dropfile-horizontal: 1.5rem;
  --bv-size-space-dropfile-vertical: 1.5rem;
  --bv-size-space-dropfile-gap: 0.5rem;
  --bv-size-space-form-section-gap: 3rem;
  --bv-size-space-form-row-gap: 1.5rem;
  --bv-size-space-table-row-vertical-padding-base: 0.75rem;
  --bv-size-space-table-row-vertical-padding-compact: 0.625rem;
  --bv-size-space-table-row-horizontal-padding-base: 1rem;
  --bv-size-space-table-row-horizontal-padding-compact: 0.625rem;
  --bv-size-space-table-row-horizontal-padding-no-padding: 0;
  --bv-size-space-table-column-inner-padding-horizontal: 0.75rem;
  --bv-size-space-tabs-horizontal: 0;
  --bv-size-space-tabs-vertical: 0.625rem;
  --bv-size-space-tablist-gap: 1.5rem;
  --bv-size-space-tag-horizontal: 0.5rem;
  --bv-size-space-tag-vertical: 0.25rem;
  --bv-size-space-tag-gap: 0.25rem;
  --bv-size-space-tag-sm: 0;
  --bv-size-space-tag-actiongap: 0.5rem;
  --bv-size-border-width-badge: 0;
  --bv-size-border-width-banner: 0.125rem;
  --bv-size-border-width-button-none: 0;
  --bv-size-border-width-button-outline: 0.125rem;
  --bv-size-border-width-calendar-day-container: 0;
  --bv-size-border-width-none: 0;
  --bv-size-border-width-base: 0.125rem;
  --bv-size-border-width-lg: 0.125rem;
  --bv-size-border-width-xl: 0.1875rem;
  --bv-size-border-width-2xl: 0.25rem;
  --bv-size-border-width-3xl: 0.3125rem;
  --bv-size-border-width-4xl: 0.375rem;
  --bv-size-border-width-divider: 0.0625rem;
  --bv-size-border-width-item: 0.0625rem;
  --bv-size-border-width-dropfile: 0.125rem;
  --bv-size-border-width-focus: 0.125rem;
  --bv-size-border-width-disabled: 0.125rem;
  --bv-size-border-width-error: 0.125rem;
  --bv-size-border-width-valid: 0.125rem;
  --bv-size-border-width-selected: 0;
  --bv-size-border-width-spinner-14xl: 0.75rem;
  --bv-size-border-width-spinner-13xl: 0.75rem;
  --bv-size-border-width-spinner-12xl: 0.75rem;
  --bv-size-border-width-spinner-11xl: 0.625rem;
  --bv-size-border-width-spinner-10xl: 0.625rem;
  --bv-size-border-width-spinner-9xl: 0.625rem;
  --bv-size-border-width-spinner-8xl: 0.5rem;
  --bv-size-border-width-spinner-5xl: 0.375rem;
  --bv-size-border-width-spinner-7xl: 0.5rem;
  --bv-size-border-width-spinner-6xl: 0.375rem;
  --bv-size-border-width-spinner-4xl: 0.3125rem;
  --bv-size-border-width-spinner-3xl: 0.3125rem;
  --bv-size-border-width-spinner-2xl: 0.25rem;
  --bv-size-border-width-spinner-xl: 0.25rem;
  --bv-size-border-width-spinner-lg: 0.1875rem;
  --bv-size-border-width-spinner-base: 0.1875rem;
  --bv-size-border-width-spinner-sm: 0.125rem;
  --bv-size-border-width-spinner-xs: 0.125rem;
  --bv-size-border-width-table-row-base: 0.0625rem;
  --bv-size-border-width-table-row-no-lines: 0;
  --bv-size-border-width-table-header-base: 0;
  --bv-size-border-width-table-label-container-base: 0.0625rem;
  --bv-size-border-width-tag: 0;
  --bv-size-breakpoint-xs: 32rem;
  --bv-size-breakpoint-sm: 48rem;
  --bv-size-breakpoint-base: 64rem;
  --bv-size-breakpoint-lg: 80rem;
  --bv-size-breakpoint-xl: 80.0625rem;
  --bv-size-font-xs: 0.75rem;
  --bv-size-font-sm: 0.875rem;
  --bv-size-font-base: 1rem;
  --bv-size-font-lg: 1.125rem;
  --bv-size-font-xl: 1.25rem;
  --bv-size-font-2xl: 1.5rem;
  --bv-size-font-3xl: 1.75rem;
  --bv-size-font-4xl: 2rem;
  --bv-size-font-5xl: 2.5rem;
  --bv-size-font-6xl: 3rem;
  --bv-size-font-7xl: 3.5rem;
  --bv-size-font-8xl: 4rem;
  --bv-size-font-9xl: 4.5rem;
  --bv-size-font-10xl: 5rem;
  --bv-size-line-height-xs: 1rem;
  --bv-size-line-height-sm: 1.25rem;
  --bv-size-line-height-base: 1.5rem;
  --bv-size-line-height-lg: 1.75rem;
  --bv-size-line-height-xl: 2rem;
  --bv-size-line-height-2xl: 2.5rem;
  --bv-size-line-height-3xl: 3rem;
  --bv-size-line-height-4xl: 3.5rem;
  --bv-size-line-height-5xl: 4rem;
  --bv-size-line-height-6xl: 4.5rem;
  --bv-size-line-height-7xl: 2.5rem;
  --bv-size-global-none: 0;
  --bv-size-global-3xs: 0.25rem;
  --bv-size-global-2xs: 0.5rem;
  --bv-size-global-xs: 1rem;
  --bv-size-global-sm: 1.25rem;
  --bv-size-global-base: 1.5rem;
  --bv-size-global-lg: 2rem;
  --bv-size-global-xl: 2.5rem;
  --bv-size-global-2xl: 3rem;
  --bv-size-global-3xl: 3.5rem;
  --bv-size-global-4xl: 4rem;
  --bv-size-global-5xl: 4.5rem;
  --bv-size-global-6xl: 5rem;
  --bv-size-global-7xl: 6rem;
  --bv-size-global-8xl: 7.5rem;
  --bv-size-global-9xl: 8.75rem;
  --bv-size-global-10xl: 10rem;
  --bv-size-global-11xl: 12.5rem;
  --bv-size-global-12xl: 14rem;
  --bv-size-global-13xl: 16rem;
  --bv-size-global-14xl: 20rem;
  --bv-size-paragraph-spacing-xs: 1rem;
  --bv-size-paragraph-spacing-sm: 1.25rem;
  --bv-size-paragraph-spacing-base: 1.5rem;
  --bv-size-paragraph-spacing-lg: 2rem;
  --bv-size-paragraph-spacing-xl: 2.5rem;
  --bv-size-paragraph-spacing-2xl: 3rem;
  --bv-size-paragraph-spacing-3xl: 3.5rem;
  --bv-size-paragraph-spacing-none: 0;
  --bv-size-icon-original: none;
  --bv-size-icon-xs: 1rem;
  --bv-size-icon-sm: 1.25rem;
  --bv-size-icon-base: 1.5rem;
  --bv-size-icon-lg: 2rem;
  --bv-size-icon-xl: 2.5rem;
  --bv-size-icon-2xl: 3rem;
  --bv-size-icon-3xl: 3.5rem;
  --bv-size-icon-4xl: 4rem;
  --bv-size-icon-5xl: 4.5rem;
  --bv-size-icon-6xl: 5rem;
  --bv-size-modal-xs: 22.5rem;
  --bv-size-modal-sm: 33.75rem;
  --bv-size-modal-base: 46.25rem;
  --bv-size-modal-lg: 56.25rem;
  --bv-size-modal-xl: 80rem;
  --bv-size-spinner-base: 1.5rem;
  --bv-size-spinner-xs: 1rem;
  --bv-size-spinner-5xl: 4.5rem;
  --bv-size-spinner-7xl: 6rem;
  --bv-size-spinner-6xl: 5rem;
  --bv-size-spinner-4xl: 4rem;
  --bv-size-spinner-3xl: 3.5rem;
  --bv-size-spinner-2xl: 3rem;
  --bv-size-spinner-8xl: 7.5rem;
  --bv-size-spinner-9xl: 8.75rem;
  --bv-size-spinner-10xl: 10rem;
  --bv-size-spinner-11xl: 12.5rem;
  --bv-size-spinner-12xl: 14rem;
  --bv-size-spinner-13xl: 16rem;
  --bv-size-spinner-14xl: 20rem;
  --bv-size-spinner-xl: 2.5rem;
  --bv-size-spinner-sm: 1.25rem;
  --bv-size-spinner-lg: 2rem;
  --bv-size-toggle-height-base: 1.5rem;
  --bv-size-toggle-height-lg: 2rem;
  --bv-color-background-avatar-base: rgb(37, 88, 176);
  --bv-color-background-badge-active: rgb(44, 105, 209);
  --bv-color-background-badge-passive: rgb(124, 124, 124);
  --bv-color-background-badge-primary: rgb(69, 233, 173);
  --bv-color-background-badge-completed: rgb(28, 163, 35);
  --bv-color-background-badge-rejected: rgb(210, 54, 45);
  --bv-color-background-banner-info: rgb(213, 225, 246);
  --bv-color-background-banner-warning: rgb(249, 229, 204);
  --bv-color-background-banner-success: rgb(210, 237, 211);
  --bv-color-background-banner-error: rgb(246, 215, 213);
  --bv-color-background-calendar-day-base: rgba(0, 0, 0, 0);
  --bv-color-background-calendar-day-hover: rgb(236, 253, 247);
  --bv-color-background-calendar-day-active: rgb(218, 251, 239);
  --bv-color-background-calendar-day-selected: rgb(69, 233, 173);
  --bv-color-background-calendar-day-line: rgb(86, 135, 218);
  --bv-color-background-calendar-container-calendar: rgb(255, 255, 255);
  --bv-color-background-check-disabled-base: rgb(239, 239, 240);
  --bv-color-background-check-unselected-base: rgb(255, 255, 255);
  --bv-color-background-check-unselected-hover: rgb(255, 255, 255);
  --bv-color-background-check-unselected-pressed: rgb(255, 255, 255);
  --bv-color-background-check-selected-base: rgb(69, 233, 173);
  --bv-color-background-check-selected-hover: rgb(58, 196, 145);
  --bv-color-background-check-selected-pressed: rgb(50, 168, 125);
  --bv-color-background-dropfile-base: rgb(243, 245, 249);
  --bv-color-background-dropfile-hover: rgb(255, 255, 255);
  --bv-color-background-dropfile-focus: rgb(255, 255, 255);
  --bv-color-background-dropfile-error: rgb(255, 255, 255);
  --bv-color-background-form-fields-disabled: rgb(239, 239, 240);
  --bv-color-background-form-fields-base: rgb(255, 255, 255);
  --bv-color-background-form-fields-hover: rgb(255, 255, 255);
  --bv-color-background-form-fields-focus: rgb(255, 255, 255);
  --bv-color-background-form-fields-pressed: rgb(255, 255, 255);
  --bv-color-background-modal: rgb(255, 255, 255);
  --bv-color-background-popover-base: rgb(255, 255, 255);
  --bv-color-background-sidebar-decorative-bar-selected: rgb(0, 0, 0);
  --bv-color-background-sidebar-container-base: rgb(0, 0, 0);
  --bv-color-background-spinner: rgb(106, 237, 189);
  --bv-color-background-table-row-unselected-base: rgb(255, 255, 255);
  --bv-color-background-table-row-unselected-hover: rgb(236, 253, 247);
  --bv-color-background-table-row-selected-base: rgb(218, 251, 239);
  --bv-color-background-table-row-selected-hover: rgb(181, 246, 222);
  --bv-color-background-table-header-base: rgba(0, 0, 0, 0);
  --bv-color-background-table-label-container-base: rgb(255, 255, 255);
  --bv-color-background-tab-divider-base: rgb(208, 208, 209);
  --bv-color-background-tab-selected-base: rgba(0, 0, 0, 0);
  --bv-color-background-tab-selected-hover: rgba(0, 0, 0, 0);
  --bv-color-background-tab-unselected-base: rgba(0, 0, 0, 0);
  --bv-color-background-tab-unselected-hover: rgba(0, 0, 0, 0);
  --bv-color-background-tag-base: rgb(243, 245, 249);
  --bv-color-background-tag-hover: rgb(243, 245, 249);
  --bv-color-background-tag-pressed: rgb(243, 245, 249);
  --bv-color-background-timeline-dot: rgba(0, 0, 0, 0);
  --bv-color-background-toast-success: rgb(28, 163, 35);
  --bv-color-background-toast-info: rgb(44, 105, 209);
  --bv-color-background-toast-warning: rgb(226, 124, 0);
  --bv-color-background-toast-error: rgb(210, 54, 45);
  --bv-color-background-toggle-default-base: rgb(208, 208, 209);
  --bv-color-background-toggle-default-hover: rgb(177, 177, 178);
  --bv-color-background-toggle-default-disabled: rgb(239, 239, 240);
  --bv-color-background-toggle-selected-base: rgb(69, 233, 173);
  --bv-color-background-toggle-selected-hover: rgb(58, 196, 145);
  --bv-color-background-toggle-selected-disabled: rgb(181, 246, 222);
  --bv-color-background-handle-default: rgb(20, 22, 25);
  --bv-color-background-handle-selected: rgb(20, 22, 25);
  --bv-color-background-tooltip: rgb(255, 255, 255);
  --bv-color-text-avatar-base: rgb(255, 255, 255);
  --bv-color-text-badge-active: rgb(255, 255, 255);
  --bv-color-text-badge-passive: rgb(255, 255, 255);
  --bv-color-text-badge-completed: rgb(255, 255, 255);
  --bv-color-text-badge-pending: rgb(255, 255, 255);
  --bv-color-text-badge-rejected: rgb(255, 255, 255);
  --bv-color-text-banner-info: rgb(0, 0, 0);
  --bv-color-text-banner-sucess: rgb(0, 0, 0);
  --bv-color-text-banner-warning: rgb(0, 0, 0);
  --bv-color-text-banner-error: rgb(0, 0, 0);
  --bv-color-text-breadcrumb-selected: rgb(83, 84, 86);
  --bv-color-text-breadcrumb-separator: rgb(83, 84, 86);
  --bv-color-text-calendar-day-base: rgb(0, 0, 0);
  --bv-color-text-calendar-day-hover: rgb(0, 0, 0);
  --bv-color-text-calendar-day-active: rgb(0, 0, 0);
  --bv-color-text-calendar-day-selected: rgb(20, 22, 25);
  --bv-color-text-calendar-day-disabled: rgb(114, 115, 117);
  --bv-color-text-calendar-weekdays-week: rgb(83, 84, 86);
  --bv-color-text-calendar-month-base: rgb(0, 0, 0);
  --bv-color-text-default: rgb(0, 0, 0);
  --bv-color-text-dropfile-help: rgb(83, 84, 86);
  --bv-color-text-dropfile-error: rgb(210, 54, 45);
  --bv-color-text-emptystate-title: rgb(0, 0, 0);
  --bv-color-text-emptystate-description: rgb(0, 0, 0);
  --bv-color-text-form-fields-hint: rgb(114, 115, 117);
  --bv-color-text-form-fields-label: rgb(83, 84, 86);
  --bv-color-text-form-fields-default: rgb(0, 0, 0);
  --bv-color-text-sidebar-unselected-hover: rgb(185, 206, 236);
  --bv-color-text-sidebar-unselected-base: rgb(185, 206, 236);
  --bv-color-text-sidebar-selected-base: rgb(255, 255, 255);
  --bv-color-text-sidebar-selected-hover: rgb(255, 255, 255);
  --bv-color-text-tab-unselected-base: rgb(60, 60, 60);
  --bv-color-text-tab-unselected-hover: rgb(0, 0, 0);
  --bv-color-text-tab-selected-base: rgb(0, 0, 0);
  --bv-color-text-tab-selected-hover: rgb(83, 84, 86);
  --bv-color-text-tag-base: rgb(0, 0, 0);
  --bv-color-text-base: rgb(0, 0, 0);
  --bv-color-text-soft: rgb(83, 84, 86);
  --bv-color-text-subtle: rgb(114, 115, 117);
  --bv-color-text-decorative: rgb(0, 0, 0);
  --bv-color-text-toast-success: rgb(255, 255, 255);
  --bv-color-text-toast-error: rgb(255, 255, 255);
  --bv-color-text-toast-info: rgb(255, 255, 255);
  --bv-color-text-toast-warning: rgb(255, 255, 255);
  --bv-color-text-tooltip: rgb(0, 0, 0);
  --bv-color-border-badge-active: rgba(0, 0, 0, 0);
  --bv-color-border-badge-passive: rgba(0, 0, 0, 0);
  --bv-color-border-badge-completed: rgba(0, 0, 0, 0);
  --bv-color-border-badge-pending: rgba(0, 0, 0, 0);
  --bv-color-border-badge-rejected: rgba(0, 0, 0, 0);
  --bv-color-border-banner-info: rgb(86, 135, 218);
  --bv-color-border-banner-sucess: rgb(73, 181, 79);
  --bv-color-border-banner-warning: rgb(232, 150, 51);
  --bv-color-border-banner-error: rgb(219, 94, 87);
  --bv-color-border-check-unselected-base: rgb(208, 208, 209);
  --bv-color-border-check-unselected-hover: rgb(177, 177, 178);
  --bv-color-border-check-unselected-pressed: rgb(145, 146, 148);
  --bv-color-border-check-selected-base: rgba(0, 0, 0, 0);
  --bv-color-border-check-selected-hover: rgba(0, 0, 0, 0);
  --bv-color-border-check-selected-pressed: rgba(0, 0, 0, 0);
  --bv-color-border-check-disabled-base: rgb(208, 208, 209);
  --bv-color-border-dropfile-base: rgb(208, 208, 209);
  --bv-color-border-dropfile-hover: rgb(177, 177, 178);
  --bv-color-border-dropfile-error: rgb(219, 94, 87);
  --bv-color-border-form-fields-base: rgb(208, 208, 209);
  --bv-color-border-form-fields-hover: rgb(177, 177, 178);
  --bv-color-border-form-fields-pressed: rgb(145, 146, 148);
  --bv-color-border-form-fields-focus: rgb(109, 147, 238);
  --bv-color-border-table-row-divider: rgb(208, 208, 209);
  --bv-color-border-table-row-compact: rgba(0, 0, 0, 0);
  --bv-color-border-table-header-divider: rgb(208, 208, 209);
  --bv-color-border-table-label-container-divider: rgb(208, 208, 209);
  --bv-color-border-tag-base: rgba(0, 0, 0, 0);
  --bv-color-border-tag-hover: rgba(0, 0, 0, 0);
  --bv-color-border-tag-pressed: rgba(0, 0, 0, 0);
  --bv-color-border-timeline-dot: rgb(177, 177, 178);
  --bv-color-border-timeline-line: rgb(208, 208, 209);
  --bv-color-icon-banner-info: rgb(44, 105, 209);
  --bv-color-icon-banner-sucess: rgb(28, 163, 35);
  --bv-color-icon-banner-warning: rgb(226, 124, 0);
  --bv-color-icon-banner-error: rgb(210, 54, 45);
  --bv-color-icon-selected-base: rgb(255, 255, 255);
  --bv-color-icon-disabled-base: rgb(0, 0, 0);
  --bv-color-icon-required: rgb(232, 150, 51);
  --bv-color-icon-sufixprefix: rgb(83, 84, 86);
  --bv-color-icon-sufixprefix-hover: rgb(0, 0, 0);
  --bv-color-icon-tag-base: rgb(0, 0, 0);
  --bv-color-icon-tag-removable-hover: rgb(58, 196, 145);
  --bv-color-icon-tag-removable-pressed: rgb(50, 168, 125);
  --bv-color-icon-tag-removable-base: rgb(83, 84, 86);
  --bv-color-button-primary-light-base: rgb(236, 253, 247);
  --bv-color-button-primary-light-hover: rgb(218, 251, 239);
  --bv-color-button-primary-light-pressed: rgb(181, 246, 222);
  --bv-color-button-primary-base: rgb(69, 233, 173);
  --bv-color-button-primary-hover: rgb(58, 196, 145);
  --bv-color-button-primary-pressed: rgb(50, 168, 125);
  --bv-color-button-primary-on-contrast: rgb(20, 22, 25);
  --bv-color-button-secondary-light-base: rgb(241, 243, 246);
  --bv-color-button-secondary-light-hover: rgb(220, 220, 220);
  --bv-color-button-secondary-light-pressed: rgb(190, 190, 190);
  --bv-color-button-secondary-base: rgb(37, 38, 39);
  --bv-color-button-secondary-hover: rgb(28, 29, 30);
  --bv-color-button-secondary-pressed: rgb(21, 21, 22);
  --bv-color-button-secondary-on-contrast: rgb(255, 255, 255);
  --bv-color-button-success-light-base: rgb(232, 246, 233);
  --bv-color-button-success-light-hover: rgb(210, 237, 211);
  --bv-color-button-success-light-pressed: rgb(164, 218, 167);
  --bv-color-button-success-base: rgb(28, 163, 35);
  --bv-color-button-success-hover: rgb(21, 124, 27);
  --bv-color-button-success-pressed: rgb(18, 104, 22);
  --bv-color-button-success-on-contrast: rgb(255, 255, 255);
  --bv-color-button-destructive-light-base: rgb(251, 235, 234);
  --bv-color-button-destructive-light-hover: rgb(246, 215, 213);
  --bv-color-button-destructive-light-pressed: rgb(237, 175, 171);
  --bv-color-button-destructive-base: rgb(210, 54, 45);
  --bv-color-button-destructive-hover: rgb(160, 41, 34);
  --bv-color-button-destructive-pressed: rgb(134, 35, 29);
  --bv-color-button-destructive-on-contrast: rgb(255, 255, 255);
  --bv-color-neutral-0: rgb(255, 255, 255);
  --bv-color-neutral-50: rgb(243, 243, 244);
  --bv-color-neutral-100: rgb(239, 239, 240);
  --bv-color-neutral-200: rgb(224, 224, 224);
  --bv-color-neutral-300: rgb(208, 208, 209);
  --bv-color-neutral-400: rgb(192, 193, 194);
  --bv-color-neutral-500: rgb(177, 177, 178);
  --bv-color-neutral-600: rgb(161, 162, 163);
  --bv-color-neutral-700: rgb(145, 146, 148);
  --bv-color-neutral-800: rgb(130, 131, 132);
  --bv-color-neutral-900: rgb(114, 115, 117);
  --bv-color-neutral-1000: rgb(98, 100, 102);
  --bv-color-neutral-1100: rgb(83, 84, 86);
  --bv-color-neutral-1200: rgb(67, 69, 71);
  --bv-color-neutral-1300: rgb(51, 53, 56);
  --bv-color-neutral-1400: rgb(36, 38, 40);
  --bv-color-neutral-1500: rgb(20, 22, 25);
  --bv-color-passive-50: rgb(242, 242, 242);
  --bv-color-passive-100: rgb(229, 229, 229);
  --bv-color-passive-200: rgb(203, 203, 203);
  --bv-color-passive-300: rgb(176, 176, 176);
  --bv-color-passive-400: rgb(150, 150, 150);
  --bv-color-passive-500: rgb(124, 124, 124);
  --bv-color-passive-600: rgb(104, 104, 104);
  --bv-color-passive-700: rgb(89, 89, 89);
  --bv-color-passive-contrast: rgb(255, 255, 255);
  --bv-color-interactive-50: rgb(236, 253, 247);
  --bv-color-interactive-100: rgb(218, 251, 239);
  --bv-color-interactive-200: rgb(181, 246, 222);
  --bv-color-interactive-300: rgb(143, 242, 206);
  --bv-color-interactive-400: rgb(106, 237, 189);
  --bv-color-interactive-500: rgb(69, 233, 173);
  --bv-color-interactive-600: rgb(58, 196, 145);
  --bv-color-interactive-700: rgb(50, 168, 125);
  --bv-color-interactive-contrast: rgb(20, 22, 25);
  --bv-color-interactive-link-base: rgb(69, 233, 173);
  --bv-color-interactive-link-hover: rgb(58, 196, 145);
  --bv-color-interactive-link-pressed: rgb(50, 168, 125);
  --bv-color-interactive-link-visited: rgb(106, 237, 189);
  --bv-color-interactive-link-disabled: rgb(114, 115, 117);
  --bv-color-warning-50: rgb(252, 242, 230);
  --bv-color-warning-100: rgb(249, 229, 204);
  --bv-color-warning-200: rgb(243, 203, 153);
  --bv-color-warning-300: rgb(238, 176, 102);
  --bv-color-warning-400: rgb(232, 150, 51);
  --bv-color-warning-500: rgb(226, 124, 0);
  --bv-color-warning-600: rgb(190, 104, 0);
  --bv-color-warning-700: rgb(163, 89, 0);
  --bv-color-warning-contrast: rgb(255, 255, 255);
  --bv-color-success-50: rgb(232, 246, 233);
  --bv-color-success-100: rgb(210, 237, 211);
  --bv-color-success-200: rgb(164, 218, 167);
  --bv-color-success-300: rgb(119, 200, 123);
  --bv-color-success-400: rgb(73, 181, 79);
  --bv-color-success-500: rgb(28, 163, 35);
  --bv-color-success-600: rgb(21, 124, 27);
  --bv-color-success-700: rgb(18, 104, 22);
  --bv-color-success-contrast: rgb(255, 255, 255);
  --bv-color-info-50: rgb(234, 240, 250);
  --bv-color-info-100: rgb(213, 225, 246);
  --bv-color-info-200: rgb(171, 195, 237);
  --bv-color-info-300: rgb(128, 165, 227);
  --bv-color-info-400: rgb(86, 135, 218);
  --bv-color-info-500: rgb(44, 105, 209);
  --bv-color-info-600: rgb(37, 88, 176);
  --bv-color-info-700: rgb(32, 76, 150);
  --bv-color-info-contrast: rgb(255, 255, 255);
  --bv-color-destructive-50: rgb(251, 235, 234);
  --bv-color-destructive-100: rgb(246, 215, 213);
  --bv-color-destructive-200: rgb(237, 175, 171);
  --bv-color-destructive-300: rgb(228, 134, 129);
  --bv-color-destructive-400: rgb(219, 94, 87);
  --bv-color-destructive-500: rgb(210, 54, 45);
  --bv-color-destructive-600: rgb(160, 41, 34);
  --bv-color-destructive-700: rgb(134, 35, 29);
  --bv-color-destructive-contrast: rgb(255, 255, 255);
  --bv-color-secondary-50: rgb(241, 243, 246);
  --bv-color-secondary-100: rgb(220, 220, 220);
  --bv-color-secondary-200: rgb(190, 190, 190);
  --bv-color-secondary-300: rgb(124, 125, 125);
  --bv-color-secondary-400: rgb(81, 81, 82);
  --bv-color-secondary-500: rgb(37, 38, 39);
  --bv-color-secondary-600: rgb(28, 29, 30);
  --bv-color-secondary-700: rgb(21, 21, 22);
  --bv-color-secondary-contrast: rgb(255, 255, 255);
  --bv-color-primary-50: rgb(236, 253, 247);
  --bv-color-primary-100: rgb(218, 251, 239);
  --bv-color-primary-200: rgb(181, 246, 222);
  --bv-color-primary-300: rgb(143, 242, 206);
  --bv-color-primary-400: rgb(106, 237, 189);
  --bv-color-primary-500: rgb(69, 233, 173);
  --bv-color-primary-600: rgb(58, 196, 145);
  --bv-color-primary-700: rgb(50, 168, 125);
  --bv-color-primary-contrast: rgb(20, 22, 25);
  --bv-color-subtle-link-base: rgb(83, 84, 86);
  --bv-color-subtle-link-hover: rgb(58, 196, 145);
  --bv-color-subtle-link-pressed: rgb(50, 168, 125);
  --bv-color-subtle-link-visited: rgb(106, 237, 189);
  --bv-color-subtle-link-disabled: rgb(114, 115, 117);
  --bv-color-selection-focus-base: rgb(109, 147, 238);
  --bv-color-selection-disabled-disabled: rgb(239, 239, 240);
  --bv-color-selection-unselected-base: rgb(208, 208, 209);
  --bv-color-selection-unselected-hover: rgb(177, 177, 178);
  --bv-color-selection-unselected-pressed: rgb(145, 146, 148);
  --bv-color-selection-selected-base: rgb(69, 233, 173);
  --bv-color-selection-selected-hover: rgb(58, 196, 145);
  --bv-color-selection-selected-pressed: rgb(50, 168, 125);
  --bv-color-separator-50: rgb(224, 224, 224);
  --bv-color-separator-100: rgb(208, 208, 209);
  --bv-color-separator-200: rgb(177, 177, 178);
  --bv-color-surface-container-primary: rgb(255, 255, 255);
  --bv-color-surface-container-secondary: rgb(243, 245, 249);
  --bv-color-surface-container-tertiary: rgb(232, 234, 240);
  --bv-color-surface-transparent: rgba(0, 0, 0, 0);
  --bv-color-surface-item: rgb(255, 255, 255);
  --bv-color-surface-overlay: rgb(255, 255, 255);
  --bv-color-backdrop-base: rgba(27, 48, 105, 0.1);
  --bv-color-divider: rgb(145, 146, 148);
  --bv-color-line-tab-selected-base: rgb(69, 233, 173);
  --bv-color-line-tab-selected-hover: rgb(69, 233, 173);
  --bv-color-line-tab-unselected-base: rgba(0, 0, 0, 0);
  --bv-color-line-tab-unselected-hover: rgba(0, 0, 0, 0);
  --bv-font-family-brand: Open Sans;
  --bv-font-family-decorative: Spartan;
  --bv-font-weight-light: 300;
  --bv-font-weight-regular: 400;
  --bv-font-weight-medium: 500;
  --bv-font-weight-semibold: 600;
  --bv-font-weight-bold: 600;
  --bv-font-weight-extrabold: 800;
  --bv-font-weight-black: 900;
  --bv-case-text-none: none;
  --bv-case-text-uppercase: uppercase;
  --bv-case-text-transform: none;
  --bv-shadow-box-base: 0px 1px 3px rgba(0, 0, 0, 0.15), inset 0px 0px 0px rgba(0, 0, 0, 0.05);
  --bv-shadow-box-item: 0px 1px 2px rgba(0, 0, 0, 0.05), 0px 0px 1px rgba(0, 0, 0, 0.25);
  --bv-shadow-box-popover: 0px 0px 2px rgba(0, 0, 0, 0.2), 0px 2px 10px rgba(0, 0, 0, 0.1);
  --bv-shadow-box-header: 0px 3px 6px rgba(0, 0, 0, 0.1);
  --bv-shadow-box-footer: 0px -3px 6px rgba(0, 0, 0, 0.1);
  --bv-shadow-box-modal: 0px 0px 1px rgba(0, 0, 0, 0.2), 0px 26px 80px rgba(0, 0, 0, 0.2);
  --bv-typography-text-header-3xl-font-family: Spartan;
  --bv-typography-text-header-3xl-font-weight: 800;
  --bv-typography-text-header-3xl-line-height: 3.5rem;
  --bv-typography-text-header-3xl-font-size: 3rem;
  --bv-typography-text-header-3xl-text-case: none;
  --bv-typography-text-header-2xl-font-family: Spartan;
  --bv-typography-text-header-2xl-font-weight: 800;
  --bv-typography-text-header-2xl-line-height: 3rem;
  --bv-typography-text-header-2xl-font-size: 2.5rem;
  --bv-typography-text-header-2xl-text-case: none;
  --bv-typography-text-header-xl-font-family: Spartan;
  --bv-typography-text-header-xl-font-weight: 800;
  --bv-typography-text-header-xl-line-height: 2.5rem;
  --bv-typography-text-header-xl-font-size: 2rem;
  --bv-typography-text-header-xl-text-case: none;
  --bv-typography-text-header-lg-font-family: Spartan;
  --bv-typography-text-header-lg-font-weight: 800;
  --bv-typography-text-header-lg-line-height: 2rem;
  --bv-typography-text-header-lg-font-size: 1.5rem;
  --bv-typography-text-header-lg-text-case: none;
  --bv-typography-text-header-lg-small-font-family: Spartan;
  --bv-typography-text-header-lg-small-font-weight: 800;
  --bv-typography-text-header-lg-small-line-height: 2rem;
  --bv-typography-text-header-lg-small-font-size: 1.25rem;
  --bv-typography-text-header-lg-small-text-case: none;
  --bv-typography-text-header-xl-small-font-family: Spartan;
  --bv-typography-text-header-xl-small-font-weight: 800;
  --bv-typography-text-header-xl-small-line-height: 2rem;
  --bv-typography-text-header-xl-small-font-size: 1.5rem;
  --bv-typography-text-header-xl-small-text-case: none;
  --bv-typography-text-header-base-font-family: Spartan;
  --bv-typography-text-header-base-font-weight: 800;
  --bv-typography-text-header-base-line-height: 2rem;
  --bv-typography-text-header-base-font-size: 1.25rem;
  --bv-typography-text-header-base-text-case: none;
  --bv-typography-text-header-base-small-font-family: Spartan;
  --bv-typography-text-header-base-small-font-weight: 800;
  --bv-typography-text-header-base-small-line-height: 1.25rem;
  --bv-typography-text-header-base-small-font-size: 1rem;
  --bv-typography-text-header-base-small-text-case: none;
  --bv-typography-text-header-sm-font-family: Spartan;
  --bv-typography-text-header-sm-font-weight: 800;
  --bv-typography-text-header-sm-line-height: 1.5rem;
  --bv-typography-text-header-sm-font-size: 1rem;
  --bv-typography-text-header-sm-text-case: none;
  --bv-typography-text-header-sm-small-font-family: Spartan;
  --bv-typography-text-header-sm-small-font-weight: 800;
  --bv-typography-text-header-sm-small-line-height: 1.5rem;
  --bv-typography-text-header-sm-small-font-size: 1rem;
  --bv-typography-text-header-sm-small-text-case: none;
  --bv-typography-text-header-xs-small-font-family: Spartan;
  --bv-typography-text-header-xs-small-font-weight: 600;
  --bv-typography-text-header-xs-small-line-height: 1.5rem;
  --bv-typography-text-header-xs-small-font-size: 1rem;
  --bv-typography-text-header-xs-small-text-case: none;
  --bv-typography-text-header-xs-font-family: Spartan;
  --bv-typography-text-header-xs-font-weight: 600;
  --bv-typography-text-header-xs-line-height: 1.25rem;
  --bv-typography-text-header-xs-font-size: 0.875rem;
  --bv-typography-text-header-xs-text-case: none;
  --bv-typography-text-body-base-font-family: Open Sans;
  --bv-typography-text-body-base-font-weight: 400;
  --bv-typography-text-body-base-line-height: 1.25rem;
  --bv-typography-text-body-base-paragraph-spacing: ;
  --bv-typography-text-body-base-text-case: none;
  --bv-typography-text-body-base-font-size: 0.875rem;
  --bv-typography-text-body-base-small-font-family: Open Sans;
  --bv-typography-text-body-base-small-font-weight: 400;
  --bv-typography-text-body-base-small-line-height: 1.5rem;
  --bv-typography-text-body-base-small-paragraph-spacing: ;
  --bv-typography-text-body-base-small-text-case: none;
  --bv-typography-text-body-base-small-font-size: 1rem;
  --bv-typography-text-body-base-highlight-font-family: Open Sans;
  --bv-typography-text-body-base-highlight-font-weight: 600;
  --bv-typography-text-body-base-highlight-line-height: 1.25rem;
  --bv-typography-text-body-base-highlight-paragraph-spacing: ;
  --bv-typography-text-body-base-highlight-text-case: none;
  --bv-typography-text-body-base-highlight-font-size: 0.875rem;
  --bv-typography-text-body-base-highlight-small-font-family: Open Sans;
  --bv-typography-text-body-base-highlight-small-font-weight: 600;
  --bv-typography-text-body-base-highlight-small-line-height: 1.5rem;
  --bv-typography-text-body-base-highlight-small-paragraph-spacing: ;
  --bv-typography-text-body-base-highlight-small-text-case: none;
  --bv-typography-text-body-base-highlight-small-font-size: 1rem;
  --bv-typography-text-body-sm-font-family: Open Sans;
  --bv-typography-text-body-sm-font-weight: 400;
  --bv-typography-text-body-sm-line-height: 1.25rem;
  --bv-typography-text-body-sm-paragraph-spacing: ;
  --bv-typography-text-body-sm-text-case: none;
  --bv-typography-text-body-sm-font-size: 0.75rem;
  --bv-typography-text-body-sm-small-font-family: Open Sans;
  --bv-typography-text-body-sm-small-font-weight: 400;
  --bv-typography-text-body-sm-small-line-height: 1.25rem;
  --bv-typography-text-body-sm-small-paragraph-spacing: ;
  --bv-typography-text-body-sm-small-text-case: none;
  --bv-typography-text-body-sm-small-font-size: 0.875rem;
  --bv-typography-text-body-sm-highlight-font-family: Open Sans;
  --bv-typography-text-body-sm-highlight-font-weight: 500;
  --bv-typography-text-body-sm-highlight-line-height: 1.25rem;
  --bv-typography-text-body-sm-highlight-paragraph-spacing: ;
  --bv-typography-text-body-sm-highlight-text-case: none;
  --bv-typography-text-body-sm-highlight-font-size: 0.75rem;
  --bv-typography-text-body-lg-font-family: Open Sans;
  --bv-typography-text-body-lg-font-weight: 400;
  --bv-typography-text-body-lg-line-height: 1.5rem;
  --bv-typography-text-body-lg-paragraph-spacing: ;
  --bv-typography-text-body-lg-text-case: none;
  --bv-typography-text-body-lg-font-size: 1rem;
  --bv-typography-text-body-lg-small-font-family: Open Sans;
  --bv-typography-text-body-lg-small-font-weight: 400;
  --bv-typography-text-body-lg-small-line-height: 1.75rem;
  --bv-typography-text-body-lg-small-paragraph-spacing: ;
  --bv-typography-text-body-lg-small-text-case: none;
  --bv-typography-text-body-lg-small-font-size: 1.125rem;
  --bv-typography-text-button-base-font-family: Open Sans;
  --bv-typography-text-button-base-font-weight: 600;
  --bv-typography-text-button-base-line-height: 1.5rem;
  --bv-typography-text-button-base-paragraph-spacing: ;
  --bv-typography-text-button-base-text-case: none;
  --bv-typography-text-button-base-font-size: 0.875rem;
  --bv-typography-text-button-lg-font-family: Open Sans;
  --bv-typography-text-button-lg-font-weight: 600;
  --bv-typography-text-button-lg-line-height: 1.5rem;
  --bv-typography-text-button-lg-paragraph-spacing: ;
  --bv-typography-text-button-lg-text-case: none;
  --bv-typography-text-button-lg-font-size: 1rem;
  --bv-typography-text-caption-font-family: Open Sans;
  --bv-typography-text-caption-font-weight: 600;
  --bv-typography-text-caption-line-height: 1.25rem;
  --bv-typography-text-caption-paragraph-spacing: ;
  --bv-typography-text-caption-text-case: uppercase;
  --bv-typography-text-caption-font-size: 0.75rem;
  --bv-typography-text-caption-small-font-family: Open Sans;
  --bv-typography-text-caption-small-font-weight: 600;
  --bv-typography-text-caption-small-line-height: 1.25rem;
  --bv-typography-text-caption-small-paragraph-spacing: ;
  --bv-typography-text-caption-small-text-case: uppercase;
  --bv-typography-text-caption-small-font-size: 0.875rem;
  --bv-typography-text-valueform-font-family: Open Sans;
  --bv-typography-text-valueform-font-weight: 400;
  --bv-typography-text-valueform-line-height: 1.25rem;
  --bv-typography-text-valueform-paragraph-spacing: ;
  --bv-typography-text-valueform-text-case: none;
  --bv-typography-text-valueform-font-size: 0.875rem;
  --bv-typography-text-label-font-family: Open Sans;
  --bv-typography-text-label-font-weight: 600;
  --bv-typography-text-label-line-height: 1.25rem;
  --bv-typography-text-label-font-size: 0.875rem;
  --bv-typography-text-label-text-case: none;
  --bv-typography-text-label-small-font-family: Open Sans;
  --bv-typography-text-label-small-font-weight: 400;
  --bv-typography-text-label-small-line-height: 1.5rem;
  --bv-typography-text-label-small-font-size: 1rem;
  --bv-typography-text-label-small-text-case: none;
  --bv-link-underlined: underline;
  --bv-link-default: none;
}
