.organization-selector {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  height: 56px;
  cursor: pointer;

  &--image {
    border-radius: 50%;
    border: 1px solid #e3e5ec;
  }
}
